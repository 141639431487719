<template>
	<ion-page>
		<!-- Title toolbar disamping icon hamburger menu -->
		<ion-header :translucent="true">
			<ion-toolbar color="ari" class="ion-text-center">
				<ion-title>Info</ion-title>
			</ion-toolbar>
		</ion-header>

		<ion-content :fullscreen="true" class="ion-padding">
			<div class="center">
				<div>
					<h3 class="warning">Ops..</h3>
					<p>
						Token Expired / Invalid Token. <br />
						Silahkan login ulang ke aplikasi
					</p>
					<ion-button @click="logout" color="ari" expand="block"
						>Login Ulang</ion-button
					>
				</div>
			</div>
		</ion-content>
	</ion-page>
</template>

<script>
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButton,
} from "@ionic/vue";

// import {
// 	fitnessOutline,
// 	calendarOutline,
// 	callOutline,
// 	logOutOutline,
// } from "ionicons/icons";
import { inject } from "vue";
import { useRouter } from "vue-router";
export default {
	name: "ErrorPage",
	components: {
		IonContent,
		IonHeader,
		IonPage,
		IonTitle,
		IonToolbar,
		IonButton,
		// IonItem,
		// IonLabel,
		// IonIcon,
		// IonAvatar,
	},
	data() {
		return {
			isOpen: false,
		};
	},
	setup() {
		const store = inject("store");
		const router = useRouter();

		//logout
		function logout() {
			store.userVerification.logout();
			setTimeout(() => {
				router.replace("/login");
			}, 200);
		}

		return {
			// fitnessOutline,
			// calendarOutline,
			// callOutline,
			// logOutOutline,
			logout,
		};
	},
	methods: {
		IsOpenTrue() {
			this.isOpen = true;
		},
		IsOpenFalse(v) {
			this.isOpen = v;
		},
	},
};
</script>

<style scoped>
ion-item {
	cursor: pointer;
}
.bg-information {
	background-color: red;
}
.personIcon {
	color: #1a2674;
	margin-right: 10px;
	font-size: 40px;
}
ion-card,
ion-item {
	cursor: default;
}
ion-card {
	margin: 0 auto;
	padding: 20px;
	padding-bottom: 60px;
	max-width: 370px;
}
.avatar > ion-avatar {
	margin: 0 auto;
	margin-top: 10px;
	margin-bottom: 10px;
}
.toggle {
	margin: 0 auto;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.logout {
	font-weight: bold;
	font-size: 14px;
	cursor: pointer;
}
.warning {
	color: red;
}
</style>
